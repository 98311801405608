import * as React from 'react';
import { graphql } from 'gatsby';
import { createGatsbyPageComponent } from '@shapeable/ui';
import { TopicExplorerLayout } from '../../components/entities/topic-explorer-layout';

export default createGatsbyPageComponent('Topic', { layout: TopicExplorerLayout });

export const topicQuery = graphql`
query TopicQuery($id: ID!) {
  platform { entityTypeDefinition(entityTypeName: "Topic") {
    typeSlug
  }
  topic(id: $id) {
    
      __typename _schema { label pluralLabel }
      id slug name gptName __typename
      color { __typename id slug name value }
      outlineNumber
      trend { id slug name __typename }
      intro { text }
      description { text }
      openGraph { 
        title description { plain }
        image { url thumbnails { large { url } } }
      }
      organisations { 
        id name slug url
        type { id name slug }
        technologies { id name slug }
        countries { id slug name path }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      embeds {
        citations {
          slug url name authors { id name slug } 
          authorShowsEtAl edition publication accessDate 
          startPage volume footnoteNumber year
        }
        imageAssets {
          id
          slug
          image {
            id url url2x width height 
          }
        }
      }
      
      video {
        id name url
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      color { id name slug value darkValue veryDarkValue }
      banner {
        id
        image {
          id
          url
          thumbnails {
            mainBanner { id url }
          }
        }
      }
      fileAssets {
        id name slug file { id extension formattedSize url typeName } __typename
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      citations {
        __typename _schema { label pluralLabel }
        id path slug url name path
        authors { id name slug } authorShowsEtAl edition publication date accessDate startPage volume footnoteNumber year
      }
      trend {
        id name path slug __typename
        outlineNumber
        color { id name slug value }
      }
      feedEntries {
        __typename _schema { label pluralLabel }
        id name slug url path
        types { id name slug }
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      posts {
        __typename _schema { label pluralLabel }
        id name slug path __typename published
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        people { id name slug path }
      }
      subTopics {
        id name path outlineNumber slug __typename 
        color { id name value } 
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
      }
      moderators {
        __typename id name slug position
        openGraph { image { thumbnails { bubble { url url2x } card { url url2x } } } }
        organisation { id name slug }
      }
    
    
    
    innovations {
      __typename _schema { label pluralLabel }
      openGraph { description { text(truncate: { words: 20 }) } image { url url2x } }
      id slug path name
      description { text }
      horizons { name id slug type { slug value } }
      catalysts { name id slug }
      technologies { name id slug }
    }
  } }
}
`;
